$(function () {

	addDeleteBtn();

	$('#add-field').on('click', function (e) {
		e.preventDefault();
		var collectionHolder = $('div.report-custom-fields');
		var index = collectionHolder.attr('data-index');

		var item = collectionHolder
			.attr('data-prototype')
			.replace(
				/__name__/g,
				index
			);

		var $newFormLi = $(item);
		$('.extra-fields').append($newFormLi);
		$newFormLi.find('input[type="hidden"]').val(index);
		CKEDITOR.replace($newFormLi.find('textarea').attr('id'));

		collectionHolder.attr('data-index', ++index);

		addDeleteBtn();
	});

	$(document).on('click', '.delete-field', function (e) {
		e.preventDefault();
		var parent = $(this).parentsUntil($('.extra-fields')).last();
		parent.remove();
	});

	$('.reportValidateAction').on('click', function (e) {
		$('#report_state').val(2);
		const $submit = $('#submit_buttonValidAndPrintPDF');
		$submit.trigger('click');
	});

	$('.confirm-changes-button').on('click', function (e) {
		var $button = $(this);
		if ($button.hasClass('repport-can-be-modified') && $('.form-changed').hasClass('form-changed')) {
			e.preventDefault();
			$.confirm({
				title: 'Annulation des modifications',
				text: 'Vous n\'avez pas enregistré le rapport. Voulez-vous abandonner vos modifications ?',
				confirm: function () {
					window.location.href = $button.attr('href');
				},
				cancel: function () {
				}
			});
		}
	});

	$("form#formReport :input").on('change', function () {
		$('.confirm-changes-button').addClass('form-changed');
	});

	$(".reportValidAndPrintPDF").confirm({
		confirm: async function (button) {
			$('#report_printPDF').val(1);
			$('button[type="submit"]').trigger('click');
			await new Promise(function (r) {return setTimeout(r, 3000)})
			window.location.reload();
		}
	});

	CKEDITOR.basePath = '/lib/ckeditor/';
	$('.ckeditor-textarea').each(function () {
		CKEDITOR.replace(this.id, {
			customConfig: '',
			extraPlugins: 'panelbutton,colorbutton,justify',
			toolbarGroups: [
				{name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
				{name: 'paragraph', groups: ['list', 'indent', 'blocks', 'bidi']},
				{name: 'styles'},
				{name: 'colors'},
				'/',
				{name: 'align'},
				{name: 'links'},
				{name: 'insert'}
			]
		}).on('change', function () {
			console.log("changes");
			$('.confirm-changes-button').addClass('form-changed');
		});
	});

	function addDeleteBtn() {
		$('.extra-fields input[type="text"]').each(function (i, el) {
			if (!$(this).hasClass('has-delete')) {
				$(this).wrap('<div class="input-group"></div>');
				$('<span class="input-group-btn"><button class="btn btn-danger delete-field"><i class="fa fa-times"></i></button></span>').insertAfter($(this));
				$(this).addClass('has-delete');
			}
		});
	}
});
